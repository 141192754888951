.title {
  font-size: 22px;
  margin-top: -15px;
  margin-left: 15px;
  margin-right: 15px;
}

.green-title {
  color: #207f4a;
}

.sub-title {
  font-size: 14px;
  color: gray;
  margin-left: 15px;
}

.home-pic {
  margin-top: -20px;
}

.home-card {
  box-shadow: none;
}

.home-button {
  width: 100%;
}

.user-avatar {
  margin-right: 15px;
}

.par-button {
  --background: #7d7e3d;
  margin-top: 20px;
}

.filter-button {
  font-size: 9px;
  margin-top: 20px;
  margin-left: 11px;
  color: white;
}

.user-single {
  border-radius: 50% !important;
}

.single-title {
  text-align: center;
}

.single-subtitle {
  text-align: center;
  font-size: 14px;
  margin-top: -10px;
}

.single-text {
  font-size: 14px;
  margin-top: -10px;
  margin-left: 20px;
}

.single-iontext {
  margin-top: 18px;
}

.single-button {
  text-align: center;
}

.products-title {
  width:60px;
}

.success-green{
  color:green;
}

.chat-input {
  margin-top:300px;
}